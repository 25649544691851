import { Box, Composition } from 'atomic-layout';
import { FooterLink, FooterText, Paragraph } from 'components/atoms/Text';

import { useTheme } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import strapi from 'utils/strapi';
import Image from "next/legacy/image";

const areas = `
socialMediaLogos
footerText
`;

const Footer = ({ footerText, links }) => {
  const theme = useTheme();
  const { isDark } = theme;
  return (
    <Composition
      areas={areas}
      justifyContent="center"
      marginTop="3rem"
      padding={1}
    >
      {(Areas) => (
        <>
          <Areas.SocialMediaLogos
            flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={3}
            paddingVertical={2}
          >
            <a
              href={links?.twitterLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              <Image
                src={require('images/twitter-brands.svg')}
                alt="Twitter"
                width={30}
                height={30}
                style={{
                  filter: !isDark && 'invert(1)',
                  width: '30px',
                  height: '30px',
                }}
              />
            </a>
            <a
              href={links.instagramLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
            >
              <Image
                src={require('images/instagram-brands.svg')}
                alt="Instagram"
                width={30}
                height={30}
                style={{
                  filter: !isDark && 'invert(1)',
                  width: '30px',
                  height: '30px',
                }}
              />
            </a>
            <a
              href={links.facebookLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook"
            >
              <Image
                src={require('images/facebook-f-brands.svg')}
                alt="Facebook"
                width={30}
                height={30}
                style={{
                  filter: !isDark && 'invert(1)',
                  width: '30px',
                  height: '30px',
                }}
              />
            </a>
          </Areas.SocialMediaLogos>
          <Areas.FooterText>
            <Box maxWidthMd={400} widthSmDown={'100%'} justifyContent="center">
              <FooterText $textAlign="center" $padding="initial">
                <ReactMarkdown>{footerText}</ReactMarkdown>
              </FooterText>
            </Box>
            <Box maxWidthMd={400} widthSmDown={'100%'} justifyContent="center">
              <Paragraph $textAlign="center" $padding="0 0 2rem 0">
                © SatsGoal {new Date().getFullYear()}.
              </Paragraph>
            </Box>
          </Areas.FooterText>
        </>
      )}
    </Composition>
  );
};

export default Footer;

export async function getFooterProps() {
  const { data } = await strapi('/api/footer');
  const { twitterLink, instagramLink, facebookLink } = data.attributes;

  return {
    footerText: data.attributes.footerText,
    links: {
      twitterLink: twitterLink || null,
      instagramLink: instagramLink || null,
      facebookLink: facebookLink || null,
    },
  };
}
